import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PontinhoState } from '../interfaces';
import { addScore, resetScores } from '../actions';

const Scoreboard = () => {
  const players = useSelector((state: PontinhoState) => state.players);
  const scores = useSelector((state: PontinhoState) => state.scores);
  const dispatch = useDispatch();
  const [ score, setScore ] = useState(players.map(() => 0));
  const winnerIndexes = scores.reduce((acc, cur, index) => { const x = cur.reduce((a, b) => a + b, 0); if (x < 100) { acc.push(index); } return acc; }, []);
  const winner = winnerIndexes.length === 1 ? players[winnerIndexes[0]] : undefined;

  const handleScoreReset = () => {
    if (window.confirm('Are you sure that you want to clear the scores?')) {
      dispatch(resetScores());
    }
  }

  const getPlayerScore = (playerIndex: number): number => {
    return score[playerIndex];
  }

  const setPlayerScore = (playerIndex: number, playerScore: string) => {
    const newScore = [ ...score ];
    newScore[playerIndex] = parseInt(playerScore);
    setScore(newScore);
  }

  const handleSaveScore = () => {
    score.forEach((playerScore, index) => {
      dispatch(addScore(index, isNaN(playerScore) ? 0 : playerScore));
    });
    setScore(players.map(() => 0));
  }

  return (
    <div>
      <h2>Scoreboard <button onClick={ handleScoreReset }>Reset</button></h2>
      {winner ? <h3>Winner: {winner}</h3> : null}
      <hr />
      { scores?.length > 0 && 
        <>
          <table>
            <thead>
              <tr>
                <th>Player</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
              { players.map((player, index) => 
                <tr key={ index }>
                  <td>{ player }</td>
                  <td>{ 100 - scores[index].reduce((acc, cur) => acc + cur, 0) }</td>
                </tr>
              )}
            </tbody>
          </table>
          <hr />
        </>
      }
      <table>
        <thead>
          <tr>
            <th>Rounds</th>
            { players.map((player, index) => <th key={ index }>{ player }</th>) }
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Current</td>
            { players.map((_, index) => 
              <td key={ index }><input type="number" placeholder="Enter score" value={ getPlayerScore(index) } min={0} max={100} onChange={(e) => setPlayerScore(index, e.target.value)} /></td>)
            }
            <td><button onClick={ handleSaveScore }>Save</button></td>
          </tr>
          { scores?.length > 0 && scores[0].map((_, userScoresIndex) => (
            <tr key={ userScoresIndex }>
              { userScoresIndex === 0 &&
                <td>Starts with</td>
              }
              { userScoresIndex > 0 &&
                <td>Round { userScoresIndex }</td>
              }
              { players.map((_, playerIndex) => 
                <td key={ playerIndex }>{ scores[playerIndex][userScoresIndex] }</td>
              )}
              <td></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Scoreboard;
