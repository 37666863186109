import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addPlayer, removePlayer, removeAllPlayers } from '../actions';
import { PontinhoState } from '../interfaces';

const AddPlayers = () => {
  const [name, setName] = useState('');
  const players = useSelector((state: PontinhoState) => state.players);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAddPlayer = () => {
    if (name.trim() !== '') {
      dispatch(addPlayer(name));
      setName('');
    }
  };

  const handleStartGame = () => {
    if (players.length > 1) {
      navigate('/scoreboard');
    }
  };

  const handleRemovePlayer = (playerIndex: number) => {
    dispatch(removePlayer(playerIndex));
  };

  const handleRemoveAllPlayers = () => {
    dispatch(removeAllPlayers());
  }

  return (
    <div>
      <h1>Add Players</h1>
      <input type="text" value={name} onChange={(e) => setName(e.target.value)} onKeyDown={(e) => { if (e.key === 'Enter') { handleAddPlayer(); } }} />
      <button onClick={handleAddPlayer}>Add Player</button>
      { players.length > 0 &&
        <>
          <h1>Players List <button onClick={ handleRemoveAllPlayers }>Remove all</button></h1>
          <ul>
            { players.map((player, index) =>
              <li key={ index }>{ player } <button onClick={ () => handleRemovePlayer(index) }>Remove</button></li>
            )}
          </ul>
        </>
      }
      { players.length > 1 &&
        <button onClick={handleStartGame}>Start Game</button>
      }
    </div>
  );
};

export default AddPlayers;
