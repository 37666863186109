import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AddPlayer from './components/AddPlayer';
import ScoreBoard from './components/ScoreBoard';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={ <AddPlayer /> } />
        <Route path="/scoreboard" element={ <ScoreBoard /> } />
      </Routes>
    </Router>
  );
};

export default App;
