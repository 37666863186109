// Action Types
export const ADD_PLAYER = 'ADD_PLAYER';
export const ADD_SCORE = 'ADD_SCORE';
export const REMOVE_PLAYER = 'REMOVE_PLAYER';
export const RESET_SCORES = 'RESET_SCORES';
export const REMOVE_ALL_PLAYERS = 'REMOVE_ALL_PLAYERS';

// Action Creators
export const addPlayer = (name: string) => ({
  type: ADD_PLAYER,
  payload: { name },
});

export const addScore = (playerIndex: number, score: number) => ({
  type: ADD_SCORE,
  payload: { playerIndex, score },
});

export const removePlayer = (playerIndex: number) => {
  return {
    type: REMOVE_PLAYER,
    payload: { playerIndex },
  };
};

export const resetScores = () => {
  return {
    type: RESET_SCORES
  }
};

export const removeAllPlayers = () => {
  return {
    type: REMOVE_ALL_PLAYERS
  }
}