import { ADD_PLAYER, ADD_SCORE, REMOVE_ALL_PLAYERS, REMOVE_PLAYER, RESET_SCORES } from '../actions';
import Cookies from 'js-cookie';
import { PontinhoAction, PontinhoState } from '../interfaces';

const initialState: PontinhoState = {
  players: JSON.parse(Cookies.get('players') ?? '[]'),
  scores: JSON.parse(Cookies.get('scores') ?? '[]'),
};

const reducer = (state = initialState, action: PontinhoAction) => {
  switch (action.type) {
    case ADD_PLAYER:
      const newPlayer = [ ...state.players, action.payload.name ];
      const newScores = [ ...state.scores, [ 0 ] ];

      Cookies.set('players', JSON.stringify(newPlayer));
      Cookies.set('scores', JSON.stringify(newScores));

      return {
        ...state,
        players: newPlayer,
        scores: newScores,
      };

    case ADD_SCORE:
      const updatedScores = state.scores.map((scoreRow, index) =>
        index === action.payload.playerIndex
          ? [...scoreRow, action.payload.score]
          : scoreRow);

      Cookies.set('scores', JSON.stringify(updatedScores));

      return {
        ...state,
        scores: updatedScores,
      };

    case REMOVE_PLAYER:
      const players = state.players.filter((_, index: number) => index !== action.payload.playerIndex);
      const scores = state.scores.filter((_, index: number) => index !== action.payload.playerIndex);

      Cookies.set('players', JSON.stringify(players));
      Cookies.set('scores', JSON.stringify(scores));

      return {
        ...state,
        players,
        scores
      };

    case RESET_SCORES:
      const resetScores = state.players.map(() => [0]);
      Cookies.set('scores', JSON.stringify(resetScores));

      return {
        ...state,
        scores: resetScores
      };

    case REMOVE_ALL_PLAYERS:
      Cookies.set('players', JSON.stringify([]));
      Cookies.set('scores', JSON.stringify([]));

      return {
        ...state,
        players: [],
        scores: []
      };
    default:
      return state;
  }
};

export default reducer;
